<template>
<vxe-modal ref="xModal" title="退房时间" width="600" show-zoom>
  <template v-slot>
    <vxe-form ref="xForm" v-bind="formOptions" v-loading="loading" @submit="submitEvent" @reset="closeModal"></vxe-form>
  </template>
</vxe-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      feeConfigLists: [],
      formOptions: {
        height: 600,
        titleWidth: 100,
        items: [{
            field: "checkOutDate",
            title: "退房时间",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择退房时间",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "120",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          checkOutDate: [{ required: true, message: "请选择退房时间" }],
        },
        // 新增功能字段初始化
        data: {
          checkOutDate: this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss"),
          remark:''
        },
      },
    };
  },

  methods: {
    ...mapActions(['findByEventService', 'postEventService']),
    // 获取数据渲染表格
    init(selectRow) {
      if (selectRow.status == 1) {
        this.$message.error('当前房间为空置房,请选择其他房间操作');
      } else {
        let { data } = this.formOptions;
        data["roomId"] = selectRow.id;
        data["roomName"] = selectRow.name;
        data["remark"] = "";
        this.$refs.xModal.open();
      }
    },

    submitEvent({ data }) {
      this.postEventService({
        service: "/communitys/tBuildingRoom/checkOut",
        params: data
      }).then(({ code, message }) => {
        if (code == 200) {
          this.$parent.selectRow = null;
          this.$message.success(`退房成功`);
          this.$parent.$refs.xGrid.getLists();
          this.closeModal()
        }
      })
    },

    closeModal() {
      this.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
