<template>
<vxe-modal ref="xModal" title="租金变更" width="800" show-zoom>
  <template v-slot>
    <vxe-form ref="xForm" v-bind="formOptions" v-loading="loading" @submit="submitEvent" @reset="closeModal"></vxe-form>
  </template>
</vxe-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      feeConfigLists: [],
      formOptions: {
        width: 800,
        height: 600,
        titleWidth: 110,
        items: [{
            field: "roomName",
            title: "当前房屋",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择当前房屋",
              },
            },
          },
          {
            field: "changeFeeDate",
            title: "变更时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择变更时间",
              },
            },
          },
          // {
          //   field: "changeFeeConfigId",
          //   title: "当前租金项",
          //   span: 12,
          //   itemRender: {
          //     name: "$select",
          //     props: {
          //       disabled: true,
          //       placeholder: "请选择当前租金项",
          //     },
          //   },
          // },
          // {
          //   field: "changeFeeConfigName",
          //   title: "当前租金",
          //   span: 12,
          //   itemRender: {
          //     name: "$select",
          //     props: {
          //       disabled: true,
          //       placeholder: "请选择当前租金项",
          //     },
          //   },
          // },
          {
            field: "changeFeeConfigId",
            title: "变更租金项",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                placeholder: "请选择变更租金项",
              },
              events: {
                change: ({ data, property }) => {
                  let item = this.feeConfigLists.filter(item => item.id === data[property]);
                  data["changeFeeConfigName"] = item.length ? item[0].squarePrice : ""
                }
              }
            },
          },
          {
            field: "changeFeeConfigName",
            title: "租金",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                disabled: true,
                placeholder: "请选择租金项",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "120",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          roomName: [{ required: true, message: "请输入当前房屋" }],
          changeFeeDate: [{ required: true, message: "请选择变更时间" }],
          changeFeeConfigId: [{ required: true, message: "请选择租金项" }],
          changeFeeConfigName: [{ required: true, message: "请选择租金" }],

        },
        // 新增功能字段初始化
        data: {
          changeFeeDate: this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss"),
          roomId: "",
          remark: "",
          changeFeeConfigId: "",
          changeFeeConfigName: "",
        },
      },
    };
  },

  methods: {
    ...mapActions(['findByEventService', 'postEventService']),
    // 获取数据渲染表格
    init(selectRow) {
      if (selectRow.status == 1) {
        this.$message.error('当前房间为空置房,请选择其他房间操作');
      } else {
        this.getFeeConfigLists(selectRow);
        this.formOptions.data = {
          changeFeeDate: this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss"),
          roomId: "",
          changeFeeConfigId: "",
          changeFeeConfigName: "",
        }
        let { data } = this.formOptions;
        data["roomId"] = selectRow.id;
        data["roomName"] = selectRow.name;
        data["remark"] = "";
        this.$refs.xModal.open();
      }
    },

    submitEvent({ data }) {
      this.postEventService({
        service: "/communitys/tCheckInLedger/updateFeeConfig",
        params: data
      }).then(({ code, message }) => {
        if (code == 200) {
          this.$parent.selectRow = null;
          this.$parent.$refs.xGrid.getLists();
          this.$message.success("租金变更成功");
          this.closeModal();
        }
      })
    },

    // 获取费用项
    getFeeConfigLists(node) {
      this.findByEventService({
          service: "/fee/fPayFeeConfig",
          params: {
            parentId: node.communityId || node.id
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.feeConfigLists = data;
            this.formOptions.items = [...this.$Tools.buildDataToGrid(this.formOptions.items, data, "changeFeeConfigId", { value: "id", label: "feeName" })];
          }
        });
    },

    closeModal() {
      this.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
