<template>
<vxe-modal ref="xModal" title="调房" width="800" show-zoom>
  <template v-slot>
    <vxe-form ref="xForm" v-bind="formOptions" v-loading="loading" @submit="submitEvent" @reset="closeModal"></vxe-form>
  </template>
</vxe-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      formOptions: {
        width: 800,
        height: 600,
        titleWidth: 100,
        items: [{
            field: "changeRoomName",
            title: "当前房屋",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择当前房屋",
              },
            },
          },
          {
            field: "roomName",
            title: "调房",
            span: 12,
            itemRender: {
              name: "$VxeSelectRoom",
              defaultProps: {
                label: "name",
                value: "id",
              },
              props: {
                maxlength: 24,
                placeholder: "请选择调房",
              },
              events: {
                click: (params, row) => {
                  let { data, property } = params;
                  data[property] = row.name;
                  data["roomId"] = row.id;
                },
              },
            },
          },
          {
            field: "changeRoomDate",
            title: "调房时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择调房时间",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "120",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          changeRoomName: [{ required: true, message: "请选择当前房屋" }],
          roomName: [{ required: true, message: "请选择调房" }],
          changeRoomDate: [{ required: true, message: "请选择调房时间" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          remark: "",
          changeRoomDate: this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss")
        },
      },
    };
  },

  methods: {
    ...mapActions(['submitEventService']),
    // 获取业主数据渲染表格
    init(selectRow) {
      if (selectRow.status == 1) {
        this.$message.error('当前房间为空置房,请选择其他房间操作');
      } else {
        let { data } = this.formOptions;
        data["roomId"] = "";
        data["roomName"] = "";
        data["changeRoomDate"] = "";
        data["remark"] = "";
        // 查询房屋状态为1的
        data["roomStatus"] = "1";
        data["parentId"] = selectRow.communityId;
        data["changeRoomId"] = selectRow.id;
        data["changeRoomName"] = selectRow.name;
        data["ownerId"] = selectRow.ownerId;
        this.$refs.xModal.open();
      }

    },

    submitEvent({ data }) {
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          this.submitEventService({
            service: "/communitys/tBuildingRoom/changeRoom",
            params: data
          }).then(({ code, message }) => {
            if (code == 200) {
              this.$parent.selectRow = null;
              this.$message.success(`调房成功`);
              this.$parent.$refs.xGrid.getLists();
              this.$refs.xModal.close();
            }
          })
        }
      });
    },

    closeModal() {
      this.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
