<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/unit" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <!-- 台账功能-调房 -->
  <ChangeRooms ref="changeRooms" />
  <!-- 台账功能-租金变更 -->
  <ChangeRent ref="changeRent" />

  <!-- 查看记录 -->
  <vxe-modal ref="history" title="查看记录" width="1000" height="600" show-zoom>
    <template v-slot>
      <History :selectRow="selectRow" />
    </template>
  </vxe-modal>

  <!-- 退房时间 -->
  <checkOut ref="checkOut" />
</div>
</template>

<script>
import { mapActions } from 'vuex';
import ChangeRooms from './ChangeRooms'
import ChangeRent from './ChangeRent'
import History from './History'
import checkOut from './checkOut'
export default {
  components: {
    ChangeRooms,
    ChangeRent,
    History,
    checkOut
  },
  data() {
    return {
      loading: false,
      currNode: {},
      selectRow: null,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "changeRooms",
              name: "调房",
              status: "primary",
              events: {
                click: () => {
                  this.parameterEvent("changeRooms")
                }
              }
            },
            {
              code: "checkOut",
              name: "退房",
              status: "danger",
              events: {
                click: () => {
                  this.parameterEvent("checkOut")
                }
              }
            },
            {
              code: "changeRent",
              name: "租金变更",
              status: "warning",
              events: {
                click: () => {
                  this.parameterEvent("changeRent")
                }
              }
            },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "radio",
            width: 60,
          },
          {
            field: "name",
            title: "房屋编号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "section",
            title: "房屋户型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "section",
              props: {
                placeholder: "请选择房屋户型",
              },
            },
          },
          {
            field: "roomBuiltUpArea",
            title: "建筑面积",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          {
            field: "ownerName",
            title: this.$t("currentOwner"),
            minWidth: 120,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                placeholder: this.$t("currentPlaceholder"),
              }
            },
          },
          {
            field: "ownerLink",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                placeholder: "请输入联系电话",
              }
            },
          },
          {
            field: "status",
            title: "房屋状态",
            width: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                placeholder: "请选择房屋状态",
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "primary",
                name: 'viewRecord',
                label: "查看记录",
                events: {
                  click: (row) => {
                    this.selectRow = row;
                    this.$refs.history.open();
                  }
                }
              }, ],
            },
          },
        ],
        editConfig: {
          enabled: false,
        },
        radioConfig: {
          trigger: "row",
          highlight: true,
        },
        events: {
          'radio-change': (data) => {
            this.selectRow = data.row;
          }
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        height: 600,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "房屋编号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "status",
            title: "房屋状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                placeholder: "请选择房屋状态",
              }
            },
          },
          {
            field: "cycle",
            title: "到期范围",
            itemRender: {
              name: "$select",
              options: [{
                  value: "1",
                  label: "1个月"
                },
                {
                  value: "2",
                  label: "2个月"
                },
                {
                  value: "3",
                  label: "3个月"
                }
              ],
              props: {
                placeholder: "请选择到期范围",
              }
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: '',
          status: "",
          cycle: "",
        },
      },
    };
  },

  methods: {
    ...mapActions(['submitEventService', 'postEventService']),
    // 获取数据渲染表格
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      let { data } = this.searchOptions;
      data["parentType"] = node.type;
      data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },

    parameterEvent(code) {
      if (!this.selectRow) {
        this.$message.error("请选择房屋")
        return;
      }
      this.$refs[code].init(this.selectRow);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
