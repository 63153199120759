<template>
<VxeBasicTable ref="xGrid" service="/communitys/tCheckInLedger" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
</VxeBasicTable>
</template>

<script>
export default {
  name: "VxeFormTable",
  props: ["selectRow"],
  data() {
    return {
      loading: false,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: "createTime",
            title: "日期",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "datetime",
                placeholder: "请选择日期"
              }
            }
          },
          {
            field: "status",
            title: "变更状态",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "parameterStatus",
              props: {
                placeholder: "请选择变更状态"
              }
            }
          },
          {
            field: "roomName",
            title: "当前房屋编号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入当前房屋编号"
              }
            }
          },
          {
            field: "checkInDate",
            title: "入住日期",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择入住日期"
              }
            }
          },
          {
            field: "expirationDate",
            title: "到期时间",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择到期时间"
              }
            }
          },
          {
            field: "signDate",
            title: "签约日期",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择签约日期"
              }
            }
          },
          {
            field: "changeRoomName",
            title: "交换房屋编号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入交换房屋编号"
              }
            }
          },
          {
            field: "changeRoomDate",
            title: "换房时间",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择换房时间"
              }
            }
          },
          {
            field: "feeConfigName",
            title: "当前租金",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入当前租金"
              }
            }
          },
          {
            field: "changeFeeConfigName",
            title: "变更租金",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入变更租金"
              }
            }
          },
          {
            field: "changeFeeDate",
            title: "换租金时间",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择换租金时间"
              }
            }
          },
          {
            field: "checkOutDate",
            title: "退租日期",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择退租日期"
              }
            }
          },
          {
            field: "remark",
            title: "备注",
            minWidth: 120,
            editRender: {
              name: "$input"
            }
          },
          // {
          //   field: "ownerName",
          //   title: this.$t("ownerName"),
          //   minWidth: 160,
          //   editRender: {
          //     name: "$input",
          //     props: {
          //       maxlength: 24,
          //       placeholder: this.$t("ownerNamePlaceholder")
          //     }
          //   }
          // },
          // {
          //   field: "phone",
          //   title: "手机号",
          //   minWidth: 160,
          //   editRender: {
          //     name: "$input",
          //     props: {
          //       maxlength: 24,
          //       placeholder: "请输入手机号"
          //     }
          //   }
          // },
        ],
        //表单校验规则
        editRules: {
          userName: [
            { required: true, message: "请输入姓名" },
          ],
        },
        // editConfig: {},
        exportConfig: {},
        importConfig: {},
        editConfig: {
          enabled: false,
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 120,
        items: [],
        //表单校验规则
        rules: {

        },
        // 新增功能字段初始化
        data: {

        },
      },
      searchOptions: {
        items: [],
        data: {
          roomId: this.selectRow.id
        }
      },
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.table {
  padding: 0 10px 10px;
  overflow: hidden;
}
</style>
